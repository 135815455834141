import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Sheet`}</inlineCode>{` merupakan Permukaan yang menutupi jendela utama, berisikan konten atau bantuan tambahan yang membantu user untuk menavigasi konten di jendela utama dengan lebih baik.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
    <ImageCard title="Minimize" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAqElEQVQ4y+3O0RKCIBAFUP//GzVlVcrqSVmEoG6Q2kwzZvXQG8zcgYHdw2ZKW/B4wcAGsjuj2R/RHk5fZantQ280opUlMIEJTOBPYNy38rcJ5RYYi0h2H6dbUsvp82EN7NWIqm4hGomiJOQ7gbzcSHgvKkIpmkfvE1wO2jgopUBE8N5jWjesr+neWgvnHPRsROsFZNYQgmCMCeg1FPu3ie/MHGrnYWbwDtJY5m4Mty9XAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/c17aeb2ff71dc5afa04c46442283b978/3787d/minimize.png",
              "srcSet": ["/static/c17aeb2ff71dc5afa04c46442283b978/7fc1e/minimize.png 288w", "/static/c17aeb2ff71dc5afa04c46442283b978/3787d/minimize.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
    <ImageCard title="Half" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABM0lEQVQ4y+2S0W7DIAxF+/8/2D11atUQyLaHhgQIEO5sFqp2StK02uOQrkzAPro22XVmQG89dO8g1CfOdYNKfmxSyW2plhnM2v0Dc9IzWgXWzVdOYPAz0kvAUyVxOJ7xfqo2SOBA8Xiu0XZ2waFsoBpyKBWqWkKQqhmV85ryuOYOWDZuiOi6Dm/7PcZxxJblnEOMEdYFFGNXoKEY+NLaK5DjmhgWKZqJcQ90HsNA+96g1R00KYSwqBjHnGOsgx3CPND7kGFtq7P4exlII+p76mgFmBOnAqSU205T/L1uz2dbtvQoWmsopbJ4lmvAcsdiM/MOqQ3vfVZ5mFI0p3I/C2SHPDelJKSUDx0+BPIc8m9A4hneOnwJmB1OM/w7h+QqpZ+fNm185VWHl0sLIUSWMeYl4DcjFQgzqFTfHQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/414f09beac8aa26b36c48997495382a0/3787d/half.png",
              "srcSet": ["/static/414f09beac8aa26b36c48997495382a0/7fc1e/half.png 288w", "/static/414f09beac8aa26b36c48997495382a0/3787d/half.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
    <ImageCard title="Full" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABSklEQVQ4y62T3W6CQBCFff+XaxPb0Ka9qeKFBKoIu7Psj6czC2s1oqGVTb4MbGYPZw6wUGTR6g5SqfMwgp3A0CdnzjUW6eLQEl7fP/G0zPD88jYJ6V1mH6gbOoleCH6tcmaNVb7Bap0zm/vkPXWjfwXThbEBpDWyLMPxeMSUZbsu9srYp5HP5/chIDDSJHjv7xKG/osM0402DmQMdvsa37t9rNY6OOdgXV8Tsu99wL4+QCkdX9C1IFfHTxVRTQStqRfgPeeuEYdEBh2PTWxm1KERMc5Ra4XAB6YuPTayPEXsV1UVEXFZKc97aHMjQ7EvDpVScdwkOLbS/k1B6lwUGnP4L8HeIf9+/EIEP2Q4g8MSZVnO4dDG781aG7OUD/Zhh23boiiKiIw9i0NxJ+POkmHTzOzQDRkKM2To2WGD7XYbedih1FuH/iL4A5p5GWQtMs1OAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/cad7207b22635c933805e835bbf816d2/3787d/full.png",
              "srcSet": ["/static/cad7207b22635c933805e835bbf816d2/7fc1e/full.png 288w", "/static/cad7207b22635c933805e835bbf816d2/3787d/full.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
    <ImageCard title="Grid" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAACFElEQVQ4y81U246bMBTM/39dpVZN00sWBQjdhIsxGIyxmY4PSbXRJu1W7UMjnTBge3xmPLAxo8O/rM3/T9gPk4B4/Zu6cmyu4OUuQyz7m7rMua65ctx02HYDlDZ/VHHNTYfXG92PqOoGNatRLepGva1qBU3SG8KVlB22LbbbLbz3eMtvHEc459AZ+7rDzqyDMyuQcFkW8A8hhBtMIFfBS8A8z6LuNSEfziTqrYebOdEv0MMsZMPkYSwx6fTgZGwOC7pxJqGXtQ8IA77mWoiGKWCXtiItPfX4livBH/YV+tFjcAGfUwXHNfcJ6YO1E9LsCEUvG6WIcxhjUFYVzmUllmT5EV3Xo2maC+5IZFn3PKQf3q8eek85bhKv3DRhYkX5fuZ48OJd9Hs9lAeSPX3ZUUZrHEZK3iY1+AhpmiJJEpG8LzqRPNHHJ+K45q7keFKxm4/7Z7T9GoN3u6MQ5lmGw+HA8QXbpxMUc2edx6fkTFX+fodaOgxQTfRqkix2ej2UKDHKtdaiKHLOacSCqjyL9IeSYwS4scgIJJjmRYhiTJxfhPxyibEUHDfWv4rNvuglixKLbO0wKw390oLf70vmz2PkZl84Hjd7GJsoIy9OfDd7iUZxKkXmuVJSsZvjcwkzjGh1h/z7mbHix+Hlq2d+fims+FHXFYbBSP6qciXsmTVNP+OhxfzFd9gYZrGuOXe8fBNXwh9TIQ6x4+zVMgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/3e75415514fb57e32edaa5cff76693cc/3787d/grid.png",
              "srcSet": ["/static/3e75415514fb57e32edaa5cff76693cc/7fc1e/grid.png 288w", "/static/3e75415514fb57e32edaa5cff76693cc/3787d/grid.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
    <ImageCard title="List" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABU0lEQVQ4y+VT7W6DMAzk/Z+rL7JBqzGV5vsTgmuHBtEWSrXt3yydDFFyvtiXyrgIf4nqHxJqG/IH5d+gcFTlw9wWrUeUvIt+VlZIq2UFoSwwoYFLg9nkvAc6sySdryy1gwsTIJXOmQsJ3YW9BO3rGMcz9p6Q4OMATdPA4XCA4/EIdV3DXnjvIcYIyrhnhcp4cM6DQoUx9uBw8ziOkFLaRN/3E6F2jwqnHy4EnM9naNs2V6cg0i1QDEPK7VpVGELEqjFXJQU/JiwKpcRBdF1WWRTuBRXeVGixh8bixIzNfUxpzAqGtAJcJ5UR+7hJ+PXdwWdzgo/6hHaQL18QPYROevShm7345EOhzASp8QBOPdC0t6FvJCs+nH7ESg9L8x9jHsqrHnofMpFzDns0vEe4ZRttydQKOOfAGEMLhTdtM9wTmsWVacICzU0gL76jkGxDtyuEV0B6FyrltloSAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/6b1ad706786bfbbfe1736542f46fd2f9/3787d/list.png",
              "srcSet": ["/static/6b1ad706786bfbbfe1736542f46fd2f9/7fc1e/list.png 288w", "/static/6b1ad706786bfbbfe1736542f46fd2f9/3787d/list.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
    <ImageCard title="Content" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABuUlEQVQ4y+2SSW/iQBCF89MRE7HvcEEglh+DUCACCZDYBGdiYhuMwXEb89JVTDPjhESJkkMOsfRU3VXlr5/ddbN3BL5TN7/AHwi0Dy4vKH5FivEKeD7J/aBk70ugWpyjwG6vThUfkOz7+6mKcXG4sx2sdRPGxoL2aOBhrUN7R1Rfyz6SJd8NfLI6zdxssFwu4boufN/H8Xh8U1Q/HA5wHOc1kB3unyRIwDAMfOYh8BWH55+saRrG4zFGoxGGw+FFtD/nRoHcYDDAarUKXCoDyZ3wTuh0OgiHw0gkEojFYojH44hGo4hEIixaU45q1BMKhdBqteD5YEYA6Mpsr9dDOp1GsVhEoVBAPp9HuVxGrVZDo9FAtVrlHNVKpRJD7+46EMfTNeAJ3e49OyFoKpVCMplENptlCCmXy3GOaplMBre3f9ButyXwhUPSk/AxlP+lUqmwm3q9fonkkKLKkZrNJrvv9/tsJgBUt0wjsJGjs91uYds2TNPkvWVZfPu0VqIeqtMI7a7espSu65jP56zFYoHpdIrZbMZxMpnwBFAPHfA/8M05FJ7Hc+XJSFJDLITgPMVrc7jb/wM+A40Fznze39wrAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/a3114259072eaa90bd7c81a5d9d79931/3787d/content.png",
              "srcSet": ["/static/a3114259072eaa90bd7c81a5d9d79931/7fc1e/content.png 288w", "/static/a3114259072eaa90bd7c81a5d9d79931/3787d/content.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      